import CustomMenu from './CustomMenu';
import { forwardRef, memo } from 'react';
import {
    AppBar,
    Layout,
    Logout,
    UserMenu,
    useLocaleState,
    useUserMenu,
} from 'react-admin';
import { SideMenu } from './SideMenu';

const MyUserMenu = () => (
    <UserMenu>
        <Logout />
    </UserMenu>
);

const MyAppBar = memo(props => <AppBar {...props} userMenu={<MyUserMenu />} />);


const CustomLayout = props => <Layout {...props} menu={SideMenu} appBar={MyAppBar} />;

export default CustomLayout;