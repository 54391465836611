import * as React from "react";
import { Create, SimpleForm, TextInput, required, SelectInput } from 'react-admin';
import { Stack } from "@mui/material";
import { RichTextInput } from "ra-input-rich-text";
import { CustomRichTextInputToolbar, MyEditorOptions } from "../../Components/CustomRichText/CustomRichTextInput";

export const LegalPaperCreate = (props) => (
    <Create {...props}>
        <SimpleForm redirect="list" >
            <TextInput disabled label="Identifiant" source="id" />
            <TextInput label="Titre" source="title" validate={required()} fullWidth/>
            <SelectInput source="tag" validate={required()} choices={[
                { id: 'legals', name: 'Mentions légales' },
                { id: 'terms-of-use', name: 'Conditions d\'utilisation' },
                { id: 'privacy-policy', name: 'Politique de confidentialité' },
                { id: 'cookies', name: 'Politique de cookies' },
            ]} />
            <SelectInput source="locale" validate={required()} choices={[
                { id: 'de', name: 'Allemand' },
                { id: 'en', name: 'Anglais' },
                { id: 'es', name: 'Espagnol' },
                { id: 'fr', name: 'Français' },
                { id: 'hi', name: 'Indien' },
                { id: 'pt', name: 'Portugais' },
                { id: 'pt-br', name: 'Portugais brésilien' },
            ]} />
            <RichTextInput className="RichInput" editorOptions={MyEditorOptions} toolbar={<CustomRichTextInputToolbar />} source="content" />
        </SimpleForm>
    </Create>
);