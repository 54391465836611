import * as React from "react";
import PropTypes from 'prop-types';
import { useRecordContext } from 'react-admin';

const CustomDateField = (props) => {
    const { source } = props;
    const record = useRecordContext(props);
    var sourceList = source.split(".");
    var finalRecord = record
    if (finalRecord != null) {
        if (sourceList.length > 1) {
            for (let item of sourceList) {
                if (finalRecord != null) {
                    finalRecord = finalRecord[item]
                }
            }
        } else {
            finalRecord = record[source]
        }
    
        var finalDate = new Date(finalRecord + "Z")
        const date2 = new Date('0001-12-17T03:24:00');
        if (finalDate == null || finalDate.getTime() < date2.getTime()) {
            return <span>-</span>
        } else {
            return <span>{finalDate.toLocaleString()}</span>;
        }
    } else {
        return <span>-</span>;
    }
}

CustomDateField.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
};

export default CustomDateField;