import * as React from 'react';
import { MouseEvent, useEffect, useState } from 'react';

import { Editor } from '@tiptap/react';
import {
    ToggleButton,
    ToggleButtonGroup,
    ToggleButtonGroupProps,
} from '@mui/material';
import FormatBold from '@mui/icons-material/FormatBold';
import FormatItalic from '@mui/icons-material/FormatItalic';
import FormatUnderlined from '@mui/icons-material/FormatUnderlined';
import FormatStrikethrough from '@mui/icons-material/FormatStrikethrough';
import Code from '@mui/icons-material/Code';
import { useTranslate } from 'ra-core';
import { useTiptapEditor } from 'ra-input-rich-text';
import TableViewIcon from '@mui/icons-material/TableView';
import TableRowsIcon from '@mui/icons-material/TableRows';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import ViewWeekIcon from '@mui/icons-material/ViewWeek';

export const TableButtons = (props) => {
    const editor = useTiptapEditor();
    const translate = useTranslate();
    const [values, setValues] = useState([]);

    const addTableLabel = "Ajout table 3x3";

    const addColumnBeforeLabel = "Ajouter colonne avant";
    const addColumnAfterLabel = "Ajouter colonne après";
    const deleteColumnLabel = "Supprimer la colonne";

    const addRowBeforeLabel = "Ajouter ligne avant";
    const addRowAfterLabel = "Ajouter ligne après";
    const deleteRowLabel = "Supprimer la ligne";

    const deleteTableLabel = "Supprimer le tableau";

    useEffect(() => {
        const handleUpdate = () => {
            setValues(() =>
                FormatValues.reduce((acc, value) => {
                    if (editor && editor.isActive(value)) {
                        acc.push(value);
                    }
                    return acc;
                }, [])
            );
        };

        if (editor) {
            editor.on('update', handleUpdate);
            editor.on('selectionUpdate', handleUpdate);
        }

        return () => {
            if (editor) {
                editor.off('update', handleUpdate);
                editor.off('selectionUpdate', handleUpdate);
            }
        };
    }, [editor]);

    const handleChange = (
        event,
        newFormats
    ) => {
        FormatValues.forEach(format => {
            const shouldBeDeactivated =
                editor &&
                editor.isActive(format) &&
                !newFormats.includes(format);
            const shouldBeActivated =
                editor &&
                !editor.isActive(format) &&
                newFormats.includes(format);

            if (shouldBeDeactivated || shouldBeActivated) {
                FormatActions[format](editor);
            }
        });
    };

    return (
        <>
            <ToggleButtonGroup
                {...props}
                disabled={!editor?.isEditable}
                onChange={handleChange}
                value={values}
            >
                <ToggleButton value="addTable" aria-label={addTableLabel} title={addTableLabel}>
                    <TableViewIcon fontSize="inherit" />
                </ToggleButton>
                <ToggleButton value="addColumnBefore" aria-label={addColumnBeforeLabel} title={addColumnBeforeLabel}>
                    <AddIcon fontSize="inherit" />
                    <ViewWeekIcon fontSize="inherit" />
                </ToggleButton>
                <ToggleButton value="addColumnAfter" aria-label={addColumnAfterLabel} title={addColumnAfterLabel}>
                    <ViewWeekIcon fontSize="inherit" />
                    <AddIcon fontSize="inherit" />
                </ToggleButton>
                <ToggleButton value="deleteColumn" aria-label={deleteColumnLabel} title={deleteColumnLabel}>
                    <ViewWeekIcon fontSize="inherit" />
                    <DeleteIcon fontSize="inherit" />
                </ToggleButton>
                <ToggleButton value="addRowBefore" aria-label={addRowBeforeLabel} title={addRowBeforeLabel}>
                    <AddIcon fontSize="inherit" />
                    <TableRowsIcon fontSize="inherit" />
                </ToggleButton>
                <ToggleButton value="addRowAfter" aria-label={addRowAfterLabel} title={addRowAfterLabel}>
                    <TableRowsIcon fontSize="inherit" />
                    <AddIcon fontSize="inherit" />
                </ToggleButton>
                <ToggleButton value="deleteRow" aria-label={deleteRowLabel} title={deleteRowLabel}>
                    <TableRowsIcon fontSize="inherit" />
                    <DeleteIcon fontSize="inherit" />
                </ToggleButton>
                <ToggleButton value="deleteTable" aria-label={deleteTableLabel} title={deleteTableLabel}>
                    <TableViewIcon fontSize="inherit" />
                    <DeleteIcon fontSize="inherit" />
                </ToggleButton>
            </ToggleButtonGroup>
        </>
    );
};

const FormatValues = ['addTable', 'addColumnBefore', 'addColumnAfter', 'deleteColumn', 'addRowBefore', 'addRowAfter', 'deleteRow', 'deleteTable'];

const FormatActions = {
    addTable: (editor) => editor.chain().focus().insertTable({ rows: 3, cols: 3, withHeaderRow: true }).run(),
    addColumnBefore: (editor) => editor.chain().focus().addColumnBefore().run(),
    addColumnAfter: (editor) => editor.chain().focus().addColumnAfter().run(),
    deleteColumn: (editor) => editor.chain().focus().deleteColumn().run(),
    addRowBefore: (editor) => editor.chain().focus().addRowBefore().run(),
    addRowAfter: (editor) => editor.chain().focus().addRowAfter().run(),
    deleteRow: (editor) => editor.chain().focus().deleteRow().run(),
    deleteTable: (editor) => editor.chain().focus().deleteTable().run(),
};
