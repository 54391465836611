// in posts.js
import * as React from "react";
import { List, Datagrid, TextField, ShowButton, Filter, TextInput, Pagination, TopToolbar, CreateButton, DeleteButton, EditButton, SelectField } from 'react-admin';

const LegalPaperPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />;

const LegalPaperActions = (props) => (
    <TopToolbar>
        <CreateButton />
    </TopToolbar>
);

const LegalPapersFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Identifiant" source="id" alwaysOn />
        <TextInput label="Nom" source="name" alwaysOn />
        <TextInput label="Description" source="description" alwaysOn />
    </Filter>
);

export const LegalPapersList = (props) => (
    <List {...props} pagination={<LegalPaperPagination />} title="Motifs Préfectures" actions={<LegalPaperActions />} filters={<LegalPapersFilter />}>
        <Datagrid>
            <TextField label="Identifiant" source="id" />
            <TextField label="Titre" source="title" sortable={false} />
            <SelectField source="tag"  sortable={false} choices={[
                { id: 'legals', name: 'Mentions légales' },
                { id: 'terms-of-use', name: 'Conditions d\'utilisation' },
                { id: 'privacy-policy', name: 'Politique de confidentialité' },
                { id: 'cookies', name: 'Politique de cookies' },
            ]} />
            <SelectField source="locale"  sortable={false} choices={[
                { id: 'de', name: 'Allemand' },
                { id: 'en', name: 'Anglais' },
                { id: 'es', name: 'Espagnol' },
                { id: 'fr', name: 'Français' },
                { id: 'hi', name: 'Indien' },
                { id: 'pt', name: 'Portugais' },
                { id: 'pt-br', name: 'Portugais brésilien' },
            ]} />
            <EditButton />
            <DeleteButton />
        </Datagrid>

    </List>
);