import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, Paper, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { React, useState, useEffect, Fragment } from "react";
import { useShowController, SimpleShowLayout, TextField, EmailField, useDataProvider } from 'react-admin';
import GetAppIcon from '@mui/icons-material/GetApp';
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Icon, SvgIcon } from "@mui/material";
import { CheckoutBill } from "../Shared/CheckoutBill";

const MyShow = props => {

    const dataProvider = useDataProvider();

    const {
        basePath, // deduced from the location, useful for action buttons
        defaultTitle, // the translated title based on the resource, e.g. 'Post #123'
        loaded, // boolean that is false until the record is available
        loading, // boolean that is true on mount, and false once the record was fetched
        record, // record fetched via dataProvider.getOne() based on the id from the location
        resource, // the resource name, deduced from the location. e.g. 'posts'
        version, // integer used by the refresh feature
    } = useShowController(props);

    const [open, setOpen] = useState(false);

    const [openDialog, setOpenDialog] = useState(false);
    const [currentPaymentToConfirm, setCurrentPaymentToConfirm] = useState({});

    const [snackContent, setSnackContent] = useState('');

    const [invoices, setInvoices] = useState([]);
    const [documents, setDocuments] = useState([]);

    const [currentSubscription, setCurrentSubscription] = useState();

    useEffect(() => {
        var invoices = record?.invoices?.sort((a, b) => new Date(b.created) - new Date(a.created));
        setInvoices(invoices ? invoices : []);
        var documents = record?.documents?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        setDocuments(documents ? documents : []);

        var subscriptions = record?.subscriptions?.sort((a, b) => new Date(b.created) - new Date(a.created));
        if (subscriptions != null && subscriptions.length > 0) {
            setCurrentSubscription(subscriptions[0])
        }

    }, [record]);


    const [payments, setPayments] = useState([]);

    useEffect(() => {
        var payments = record?.payments?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        setPayments(payments ? payments : []);
    }, [record]);

    const openSnackBar = (content) => {
        setSnackContent(content);
        setOpen(true);
    };

    const handleCloseSnackBar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
        setSnackContent("");
    };

    const [anchorEl, setAnchorEl] = useState(null);

    const downloadInvoice = async (id) => {
        const html2pdf = require('html2pdf.js');

        var element = document.getElementById("tpinvoice-" + id);

        html2pdf().from(element).set({
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: {
                dpi: 192,
                scale: 5,
                letterRendering: true,
                useCORS: true
            },
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
            margin: [0, 0, 0, 0],
        }).toPdf().save("Facture-" + id + ".pdf");
    }

    const refillPayment = (paymentItem) => {
        dataProvider.getCustom('Admin/Refund', { id: paymentItem.id })
            .then((data) => {
                if (data.reason) {
                    openSnackBar('Error while refund ' + data.reason);
                } else {
                    openSnackBar('Refund successfull');
                }

            })
            .catch((e) => {
                openSnackBar('Error while refund ' + e.reason);
            });
    }

    const confirmRefillPayment = (paymentItem) => {
        refillPayment(paymentItem);
        handleCloseDialog();
    }

    const handleOpenDialog = (paymentItem) => {
        setCurrentPaymentToConfirm(paymentItem);
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const getSubscriptionStatus = (statusNumber) => {
        switch (statusNumber) {
            case 0:
                return "Trial";
            case 1:
                return "In progress";
            case 2:
                return "Late payment";
            case 3:
                return "Canceled by operator";
            case 4:
                return "Canceled for late payment";
            case 5:
                return "Canceled by client";
            default:
                return statusNumber;
        }
    }

    const revokeSubscription = () => {
        dataProvider.getCustom('Admin/revoke', { id: record.subscription.id })
            .then((data) => {
                if (data.reason) {
                    openSnackBar('Error while canceling subscription ' + data.message);
                } else {
                    openSnackBar('Subscription canceled and confirmation email sent to client');
                }
            })
            .catch((e) => {
                openSnackBar('Error while canceling subscription ' + e);

            });
    }

    const resendPassword = () => {
        dataProvider.getCustom(`Admin/ResetPassword?email=${record.email}`)
            .then(({ data }) => {
                openSnackBar(`New password has been sent to ${record.email}`);
            })
            .catch((e) => {
                openSnackBar('Error while sendind new password ' + e);

            });
    }

    const getCurrencyKey = (currency) => {
        var key = "€";

        switch (currency) {
            case "EUR":
                key = "€";
                break;
            case "BRL":
                key = "R$";
                break;
            case "INR":
                key = "र";
                break;
            case "USD":
                key = "$";
                break;
        }
        return key;
    }
    return (
        <Stack sx={{ padding: "0px 20px 50px" }}>
            <h1>{record?.uid}</h1>

            <h2>Subscription details</h2>
            <Grid container direction="row">

                <Grid item xs={6} container direction="column">
                    <Grid item>
                        Client : {record?.email}
                    </Grid>
                    <Grid item>
                        Created at : {record && record.createdAt ? new Date(record.createdAt + "Z").toLocaleString() : ''}
                    </Grid>
                    <Grid item>
                        Next payment date : { new Date(record?.subscription?.dateNextWithdrawalMonth).toLocaleDateString()} - Amount {record?.subscription?.amount / 100} {record?.subscription?.currency}
                    </Grid>
                    <Grid item>
                        Language : {record?.locale}
                    </Grid>
                    <Grid item>
                        ID : {record?.id}
                    </Grid>
                    <Grid item>
                        Status : <strong>{getSubscriptionStatus(record?.subscription?.status)}</strong>
                    </Grid>
                    <Grid item>
                        Subscription Period :<strong> {record?.subscription?.state}</strong>
                    </Grid>
                    <Grid item>
                        Subscription End :<strong> {new Date(record?.subscription?.periodEnd).toLocaleDateString()}</strong>
                    </Grid>
                    {
                        record && record.payments != null && record.payments.length > 0 &&
                        <Grid item>
                            Currency : {record?.subscription?.currency}
                        </Grid>
                    }
                    <Grid item>
                        Country : {record?.subscription?.country}
                    </Grid>
                    <br />
                    <br />
                </Grid>

                <Grid item>
                    {!(record?.subscription?.status.startsWith("canceled")) ?
                        <Button variant="contained" color="primary" onClick={revokeSubscription}>
                            Cancel subscription
                        </Button>
                        : <></>
                    }
                    <Button variant="contained" color="primary" style={{ marginLeft: "15px" }} onClick={resendPassword}>
                        Send new password
                    </Button>
                </Grid>

            </Grid>

            <h2>Invoices</h2>
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">Amount</TableCell>
                            <TableCell align="center">Status</TableCell>
                            <TableCell align="center">Bill number</TableCell>
                            <TableCell align="center">Due date</TableCell>
                            <TableCell align="center"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {invoices.map((row, index) => (
                            <>
                                <TableRow key={index}>
                                    <TableCell align="center">{row.amount / 100} {record?.subscription?.currency}</TableCell>
                                    <TableCell align="center">Paid</TableCell>
                                    <TableCell align="center">{row.invoiceNumber}</TableCell>
                                    <TableCell align="center">{row.date ? new Date(row.date).toLocaleString() : ''}</TableCell>
                                    <TableCell align="center">
                                        <IconButton color="primary" component="span" onClick={() => downloadInvoice(row.invoiceNumber)} >
                                            <GetAppIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                                <Box style={{ visibility: "hidden", height: "0px", width: "0px", overflow: "hidden" }}>
                                    <CheckoutBill invoice={row} />
                                </Box>
                            </>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <h2>Payments</h2>
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">Amount</TableCell>
                            <TableCell align="center">Status</TableCell>
                            <TableCell align="center">Payment Id</TableCell>
                            <TableCell align="center">Due date</TableCell>
                            <TableCell align="center"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {payments.map((paymentItem, index) => (
                            <TableRow key={index}>
                                <TableCell align="center">{paymentItem.amount / 100} {record?.subscription?.currency}</TableCell>
                                <TableCell align="center">{paymentItem.status}</TableCell>
                                <TableCell align="center">{paymentItem.id}</TableCell>
                                <TableCell align="center">{paymentItem.requestedOn ? new Date(paymentItem.requestedOn).toLocaleString() : ''}</TableCell>
                                <TableCell align="center">
                                    {paymentItem.status != "Refunded" && paymentItem.status != "Declined" &&
                                        <>
                                            <Button color="primary" onClick={() => handleOpenDialog(paymentItem)}>Refund</Button>
                                            <Dialog
                                                open={openDialog}
                                                onClose={handleCloseDialog}
                                                aria-labelledby="alert-dialog-title"
                                                aria-describedby="alert-dialog-description"
                                            >
                                                <DialogTitle id="alert-dialog-title">Refund</DialogTitle>
                                                <DialogContent>
                                                    <DialogContentText id="alert-dialog-description">
                                                        Are you sure you want to refund {currentPaymentToConfirm.amount / 100} {getCurrencyKey(paymentItem.currency)}
                                                    </DialogContentText>
                                                </DialogContent>
                                                <DialogActions>
                                                    <Button onClick={handleCloseDialog} color="primary">
                                                        Cancel
                                                    </Button>
                                                    <Button onClick={() => confirmRefillPayment(currentPaymentToConfirm)} color="primary" autoFocus>
                                                        Refund
                                                    </Button>
                                                </DialogActions>
                                            </Dialog>
                                        </>
                                    }
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {
                documents && documents.length > 0 && <>
                    <h2>Documents</h2>
                    <Stack spacing={{ xs: 4, md: 5 }} sx={{ width: "100%", flex: "1" }}>
                        <FileGrid files={documents} />
                    </Stack>

                </>
            }
            <Snackbar open={open} autoHideDuration={6000} onClose={handleCloseSnackBar} message={snackContent} autoHideDuration={6000} anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }} action={
                <Fragment>
                    <Button color="secondary" size="small" onClick={handleCloseSnackBar}>
                        OK
                    </Button>
                </Fragment>
            } />
        </Stack>
    );
}

export const ClientShow = (props) => (
    <MyShow {...props}>
        <SimpleShowLayout>
            <TextField source="uid" />
            <TextField source="name" />
            <EmailField source="email" />
        </SimpleShowLayout>
    </MyShow>
);

function FileGrid(props) {
    const { files } = props;

    return <Box>
        <Grid container spacing={3}>
            {files && files.map(file => <Grid item key={file.filename}>
                <FileCard file={file} refresh={props.refresh} />
            </Grid>)}
        </Grid>
    </Box>
}

function FileCard(props) {
    const { file } = props;
    const dataProvider = useDataProvider();
    const {
        record, // record fetched via dataProvider.getOne() based on the id from the location
    } = useShowController(props);


    const downloadFile = (document) => {

        dataProvider.getFile(`Admin/Document/File/${document.id}?userId=${record.id}`).then((data) => {
            // set the blog type to final pdf
            const file = new Blob([data.body], { type: 'application/pdf' });

            // process to auto download it
            const fileURL = URL.createObjectURL(file);
            const link = window.document.createElement('a');
            link.href = fileURL;
            link.download = document.originalFilename;
            link.click()
        })

            // getDocumentFileById(auth, document.id).then(fileData => {
            ;
        // });
    }

    const getDaysDifference = (date) => {
        const updatedAt = new Date(date);
        const now = new Date()
        const value = ((now.getTime() - updatedAt.getTime()) / (1000 * 3600 * 24));

        if (value < 1) {
            return " today";
        } else if (value >= 1) {
            return Math.round(value) + " days ago"
        }
    }

    return <Card sx={{ padding: 0, height: "100%" }}>
        <Box sx={{
            position: "relative",
            width: "100%", height: { xs: "250px", lg: "184px" }, overflow: "hidden"
        }}>
            <AuthenticatedImage documentId={file.id} style={{ width: "344px", borderRadius: "10px" }} />
        </Box>
        <Stack spacing={3} sx={{
            padding: "24px 24px"
        }}>
            <Stack direction="row">
                <Typography variant="h6" color="primary.main" fontWeight={700} sx={{ marginRight: "auto", overflowWrap: "anywhere" }}>
                    {file.originalFilename}
                </Typography>
                <DownloadIcon sx={{ color: "text.grey", marginRight: 1 }} clickable onClick={() => downloadFile(file)} />
                {/* <ShareIcon sx={{ color: "text.grey" }} clickable /> */}
            </Stack>
            <Stack direction="row" spacing={3}>
                {/* <FormattedTextButton icon={<EditIcon height="16px" width="16px" sx={{ color: "text.grey" }} />} tId="dashboard.files.edit" onClick={() => editDocument(file)} />
                <FormattedTextButton icon={<DuplicateIcon height="16px" width="16px" sx={{ color: "text.grey" }} />} tId="dashboard.files.duplicate" onClick={() => duplicateDocument(file)} />
                <FormattedTextButton icon={<TrashIcon height="16px" width="16px" sx={{ color: "text.grey" }} />} tId="dashboard.files.delete" onClick={() => deleteDocument(file)} /> */}
            </Stack>
            <Typography variant="mini" color="text.grey" sx={{ justifySelf: "end", alignSelf: "end" }}>
                {
                    file != null && file.updatedAt != null && getDaysDifference(file.updatedAt)
                }
            </Typography>
        </Stack>
    </Card>
}

const AuthenticatedImage = (props) => {

    const {
        record, // record fetched via dataProvider.getOne() based on the id from the location
    } = useShowController(props);

    const dataProvider = useDataProvider();
    const [image, setImage] = useState(null)
    const getThumbnail = async (docId) => {
        dataProvider.getFile(`Admin/Document/Thumbnail/${docId}?userId=${record.id}`, 'image/jpeg').then((data) => {
            var blobImage = URL.createObjectURL(new Blob([data.body], { type: "image/jpeg" }));
            setImage(blobImage)
        })
    }
    useEffect(() => {
        if (props.documentId) {
            getThumbnail(props.documentId)
        }
    }, [props.documentId])

    return (
        <> {image != null && <img src={image} style={props.style} />}</>
    )
}

export function Card(props) {
    const { sx, spacing, children, ...otherProps } = props;

    return <Stack spacing={spacing} sx={{
        padding: { xs: "40px 32px", md: "44px 44px" },
        background: "white",
        borderRadius: "10px",
        boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.05)",
        width: "342px",
        ...sx
    }} {...otherProps}>
        {children}
    </Stack>
}

export function DownloadIcon(props) {
    return <IconBase width="16px" height="16px" {...props} color={props.color ? props.color : "#141414"}>
        <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4 14H12" stroke="currentColor" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M8 2L8 11.3333" stroke="currentColor" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M11.3327 8L7.99935 11.3333L4.66602 8" stroke="currentColor" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
        </svg>

    </IconBase>;
}


function IconBase(props) {
    const { children, sx, color, height, width, clickable, ...otherProps } = props;

    return <SvgIcon
        color={color}
        htmlColor={color}
        {...otherProps}
        sx={{
            height: height ?? "24px", width: width ?? "24px",
            cursor: clickable && "pointer",
            "&:hover": {
                filter: clickable && "brightness(0.5)"
            },
            transition: "0.3s all",
            ...sx
        }}
        inheritViewBox>
        {children}
    </SvgIcon>;
}
