import { Grid } from "@mui/material";
import * as React from "react";
import { List, Datagrid, TextField, DateField, BooleanField, ShowButton, EmailField, Filter, TextInput, Pagination, useDataProvider, EditButton, useList } from 'react-admin';
import CustomDateField from "../../Components/CustomDateField";

const ClientPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />;

const ClientsFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Identifiant" source="uid" alwaysOn />
        <TextInput label="Email" source="email" alwaysOn />
    </Filter>
);

const ClientInformationsForAdmin = (props) => {

    const dataProvider = useDataProvider();

    const [dayStats, setDayStats] = React.useState([]);
    React.useEffect(async () => {
        dataProvider.getCustom('DayStats').then(res => {
            setDayStats(res.data)
        })
    }, []);

    return <div>
        <Grid container justify="flex-end">
            <Grid item className="day-data-container">
                <Grid container direction="column">
                    <Grid item><strong>Données du jour :</strong></Grid>
                    <Grid item> Souscriptions : <strong>{dayStats.subscriptionsCount}</strong></Grid>
                    <Grid item> Nombre d'alertes : <strong>{dayStats.alertsCount}</strong></Grid>
                </Grid>
            </Grid>

        </Grid>
    </div>
}

const ClientEditButton = () => <EditButton label="Détails" />;

export const ClientsList = (props) => {
    
    return (<>

        {
            props.permissions === "SuperAdmin" && <ClientInformationsForAdmin />
        }

        <List {...props} pagination={<ClientPagination />} title="Clients" actions={<></>} filters={<ClientsFilter />} sort={{ field: 'createdAt', order: 'ASC' }} >
            <>
                <Datagrid bulkActionButtons={false}>
                    <TextField label="Id" source="uid" />
                    <EmailField label="Email" source="email" />
                    <CustomDateField showTime={true} label="Creation Date" source="createdAt" locales="fr-FR" />
                    <BooleanField label="Subscribed" source="isSubscribed" sortable={false} />
                    <TextField label="Document number" source="documentNumber" sortable={false} />
                    <ClientEditButton />
                </Datagrid>
            </>
        </List>
    </>)
};