// in posts.js
import * as React from "react";
import { List, Datagrid, TextField, ShowButton, Filter, TextInput, Pagination, TopToolbar, CreateButton, DeleteButton, EditButton } from 'react-admin';

const UserPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />;

const UserActions = (props) => (
    <TopToolbar>
        <CreateButton/>
    </TopToolbar>
);

const UsersFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Identifiant" source="id" alwaysOn />
        <TextInput label="Nom" source="name" alwaysOn />
        <TextInput label="Email" source="email" alwaysOn />
    </Filter>
);

export const UsersList = (props) => (
    <List {...props} pagination={<UserPagination />} title="Utilisateurs" actions={<UserActions />} filters={<UsersFilter />}>
        <>
            <Datagrid>
                <TextField label="Identifiant" source="id" />
                <TextField label="Nom" source="lastName" sortable={false} />
                <TextField label="Prénom" source="firstName" sortable={false} />
                <TextField label="Email" source="email" sortable={false} />
                <TextField label="Role" source="role" sortable={false} />
                <EditButton />
                <DeleteButton />
            </Datagrid>
        </>
    </List>
);