import { Divider, Typography, Box } from '@mui/material';
import * as React from 'react';
import { Menu, useResourceDefinitions } from 'react-admin';

export const SideMenu = () => {
    const resources = useResourceDefinitions();

    return (
        <Menu>
            <div>
                <Typography style={{ marginLeft: "15px", marginTop: "20px", marginBottom: "20px", fontWeight: "bold" }}>Operators</Typography>
                {Object.keys(resources).map(name => (
                    <>
                        {
                            resources[name].options.isAdmin &&
                            <Box key={name}>
                                <Menu.Item
                                    key={name}
                                    to={`/${name}?filter=%7B%7D`}
                                    primaryText={resources[name].options && resources[name].options.label || name}
                                />
                                <Divider />
                            </Box>
                        }
                    </>
                ))}

                <div style={{ height: "30vh", width: "5px" }}></div>
                <Typography style={{ marginLeft: "15px", marginTop: "20px", marginBottom: "20px", fontWeight: "bold" }}>Administrators</Typography>

                {Object.keys(resources).map(name => (
                    <>
                        {
                            resources[name].options.isSuperAdmin &&
                            <Box key={name}>
                                <Menu.Item
                                    key={name}
                                    to={`/${name}`}
                                    primaryText={resources[name].options && resources[name].options.label || name}
                                />
                                <Divider />
                            </Box>
                        }
                    </>
                ))}
            </div>
        </Menu>
    );
}