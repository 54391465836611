// in src/App.js
import * as React from 'react';
import { Admin, Resource } from 'react-admin';
import authProvider from './Providers/AuthProvider';
import './App.css';
import frenchMessages from 'ra-language-french';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import PersonIcon from '@mui/icons-material/Person';
import jsonServerProvider from './Providers/DataProvider';
import { UsersList } from './Pages/Users/UserList';
import { UserCreate } from './Pages/Users/UserCreate';
import { UserEdit } from './Pages/Users/UserEdit';
import CustomLayout from './Components/CustomLayout';
import { ClientsList } from './Pages/Clients/ClientList';
import { ClientShow } from './Pages/Clients/ClientShow';
import { LegalPapersList } from './Pages/LegalPaper/LegalPaperList';
import { LegalPaperCreate } from './Pages/LegalPaper/LegalPaperCreate';
import { LegalPaperEdit } from './Pages/LegalPaper/LegalPaperEdit';

const i18nProvider = polyglotI18nProvider(() => frenchMessages, 'fr');

const App = () => (
  <Admin
    i18nProvider={i18nProvider}
    authProvider={authProvider}
    dataProvider={jsonServerProvider}
    layout={CustomLayout}>
    {permissions => [
      (permissions === 'Admin' || permissions === 'SuperAdmin') && <Resource options={{ label: 'Clients', isAdmin: true }} name="Admin" list={ClientsList} edit={ClientShow} />,
      permissions === 'SuperAdmin' && <Resource options={{ label: 'Utilisateurs', isSuperAdmin: true }} name="AdminUser" list={UsersList} create={UserCreate} edit={UserEdit}/>,
      permissions === 'SuperAdmin' && <Resource options={{ label: 'Documents légaux', isSuperAdmin: true }} name="LegalPapers" list={LegalPapersList} create={LegalPaperCreate} edit={LegalPaperEdit}/>
    ]}
  </Admin>
)

export default App;